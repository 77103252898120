import React from "react";

import c from "classnames";

import Styles from "./notice.module.scss";

type Props = {
	type?: "default" | "attention" | "offer" | "error";
	size?: "default" | "small" | "full";
	children: string;
};

export const Notice = ({
	type = "default",
	size = "default",
	children,
}: Props) => {
	return (
		<div className={c(Styles[type], Styles.notice, Styles[size])}>
			{children}
		</div>
	);
};
