import React from "react";

import c from "classnames";

import IconSearch from "@/salesComponents/icons/iconSearch.svg";

import Styles from "./input.module.scss";
import { useInput } from "./useInput";

export type ErrorMessage = {
	required: string;
	validate: string;
	pattern: string;
};

type Props = {
	type: string;
	id: string;
	register?: any;
	placeholder?: string;
	name: string;
	disabled?: boolean;
	label?: string;
	errors?: any;
	validate?: any;
	errorMessages?: ErrorMessage;
	sizing?: "full" | "relative";
	value?: string | null;
	onChange?: (value: string) => void;
	className?: string;
	step?: string;
};

export const Input = ({
	type = "text",
	id,
	register,
	placeholder,
	name,
	disabled = false,
	label,
	errors,
	validate,
	errorMessages,
	sizing = "full",
	value,
	onChange,
	className,
	...htmlInputProps
}: Props) => {
	const { errorNotice } = useInput({
		id,
		errorMessages,
		errors,
		label,
		minLength: validate?.minLength,
	});

	return (
		<div
			className={c(
				Styles.inputGroup,
				{ [Styles.search]: type === "search" },
				className
			)}
		>
			{label && (
				<label htmlFor={name} className={c(Styles.label)}>
					{label}
				</label>
			)}
			{type === "search" && <IconSearch />}
			{type === "textarea" ? (
				<textarea
					{...htmlInputProps}
					type={type}
					id={id}
					name={name}
					maxLength={validate?.maxLength}
					autoComplete="off"
					placeholder={placeholder}
					disabled={disabled}
					aria-label={label}
					value={value}
					className={c(
						Styles.input,
						{
							[Styles.inputError]: errors?.[id],
						},
						Styles[sizing]
					)}
					onChange={(e) => {
						onChange && onChange(e.target.value || "");
					}}
					{...(register &&
						register(id, {
							// valueAsDate: type === "date" || type === "time",
							...validate,
						}))}
				></textarea>
			) : (
				<input
					{...htmlInputProps}
					type={type}
					id={id}
					name={name}
					maxLength={validate?.maxLength}
					autoComplete="off"
					placeholder={placeholder}
					disabled={disabled}
					aria-label={label}
					value={value}
					className={c(
						Styles.input,
						{
							[Styles.inputError]: errors?.[id],
						},
						Styles[sizing]
					)}
					onChange={(e) => {
						onChange && onChange(e.target.value || "");
					}}
					{...(register &&
						register(id, {
							// valueAsDate: type === "date" || type === "time",
							...validate,
						}))}
				/>
			)}

			{errors?.[id] && <p className={Styles.error}>{errorNotice}</p>}
		</div>
	);
};
