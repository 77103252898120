import { useMemo } from "react";

import { ErrorMessage } from "./input"

type Props = {
	id: string;
	errors: Record<string, Record<string, string>>;
	label?: string;
	errorMessages?: ErrorMessage;
	minLength: number;
};

type Returns = {
	errorNotice: string;
};

export const useInput = ({
	errors,
	id,
	label,
	errorMessages,
	minLength
}: Props): Returns => {

	const errorNotice = useMemo(
		() => ({
			required: errorMessages?.required || `${label || id} is required`,
			pattern: errorMessages?.pattern || `${label || id}'s format is incorrect`,
			minLength: `${label || id} must be at least ${minLength} characters`,
			validate: errorMessages?.validate || `${errors?.[id]?.message}`,
		}),
		[errors, id, label, errorMessages, minLength]
	);

	return {
		errorNotice: errorNotice[errors?.[id]?.type as keyof typeof errorNotice],
	};
};