import { useEffect, useState } from "react";

import { signIn, useSession } from "next-auth/react";
import { useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { useRouter } from "next/router";

type FormValues = {
	email: string;
	password: string;
};

export const useLoginForm = () => {
	//Form
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({ mode: "onBlur" });

	// Session
	const { data: session } = useSession();

	//Router
	const router = useRouter();

	// STATES
	const [errorMsg, setErrorMsg] = useState<string | null>(null);
	const [isLoggingIn, setIsLoggingIn] = useState(false);

	const onSubmit: SubmitHandler<FormValues> = async (data) => {
		setIsLoggingIn(true);

		const info = await signIn("credentials", {
			redirect: false,
			email: data.email,
			password: data.password,
		});
		if (info?.error) {
			setIsLoggingIn(false);
			setErrorMsg("Incorrect username/password");
		} else {
			setErrorMsg(null);
		}
	};

	useEffect(() => {
		if (!session) {
			return;
		}

		router.push("dashboard");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session]);

	return {
		errorMsg,
		onSubmit,
		register,
		handleSubmit,
		errors,
		isLoggingIn,
	};
};
