import React from "react";

import { getCsrfToken } from "next-auth/react";
import { unstable_getServerSession } from "next-auth/next";
import { GetServerSideProps } from "next";

import { IntroLayout } from "@/salesComponents/layout";
import { Heading } from "@/salesComponents/elements/heading";
import { LoginForm } from "@/salesComponents/login/loginForm";

import { authOptions } from "./api/auth/[...nextauth]";

type Props = {
	csrfToken: string;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
	const session = await unstable_getServerSession(
		context.req,
		context.res,
		authOptions
	);

	if (session && session?.user?.role) {
		return {
			redirect: {
				destination: "/dashboard",
				permanent: false,
			},
		};
	}

	const csrfToken = await getCsrfToken();

	return {
		props: {
			csrfToken: csrfToken,
		},
	};
};

// eslint-disable-next-line import/no-default-export
export default function Login({ csrfToken }: Props) {
	return (
		<IntroLayout>
			<Heading heirarchy="h1" styling="h2">
				Log in to Pure Sales
			</Heading>
			<LoginForm csrfToken={csrfToken} />
		</IntroLayout>
	);
}
