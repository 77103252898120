import React from "react";

import Link from 'next/link'

import { Input } from "@/salesComponents/elements/input";
import { Button } from "@/salesComponents/elements/button";
import { Notice } from "@/salesComponents/elements/notice";

import { useLoginForm } from "./useLoginForm"

type Props = {
	csrfToken: string;
}

export const LoginForm = ({csrfToken}: Props) => {

	const {
		errorMsg,
		onSubmit,
		register,
		handleSubmit,
		errors,
		isLoggingIn
	} = useLoginForm();

	return (
		<>
			{errorMsg && (
				<Notice type="error">{errorMsg}</Notice>
			)}
			<form onSubmit={handleSubmit(onSubmit)}>
				<input name="csrfToken" type="hidden" {...register} defaultValue={csrfToken} />
				<Input name="email"
					label="Email"
					id="email"
					type="email"
					register={register}
					errors={errors}
					validate={{
						required: true,
					}} />
				<Input name="password"
					label="Password"
					id="password"
					type="password"
					register={register}
					errors={errors}
					validate={{
						required: true,
					}} />
				
				<Button type="tertiary" text="Log in" use="form" loading={isLoggingIn} />
				{/* <Link href="/"><a className="float-left">Create an account</a></Link> */}
				<Link href="/reset"><a className="float-right">Forgot password?</a></Link>
			</form>
		</>
	);
};
